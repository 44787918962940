import '../css/about_style.css'


function About() {
  // Add your component logic here
  return (
  <div className="welcome-text-area">
   <div className='boxed-list'>
  <h1>Welcome to the Construction</h1>
  </div> 
  <p>Welcome to Promptology, where we humbly share our knowledge of AI to make your life easier. Whether you're an expert or just starting out, we've got you covered with topics ranging from machine learning to natural language processing.</p>
  <p>Join us on our fascinating and entertaining journey of discovery, and let's unlock the full potential of AI together!</p>
  <p>If you're reading this, you're way ahead of the crowd. Feel free to comment, and if you feel like helping with prompts, that is always welcome.</p>
  <p>Contact us through Discord for now, please. FervorLife#6418</p>
  <p>This site is just a mock-up, but here's what's coming soon:</p>
  <div className='boxed-list'>
  <ul>
  <li>A backend that you will be able to load your own generative prompting data into</li>
  <li>Multiple UIs and UXs that are being created</li>
  <li>Educational tutorials that will be launching soon</li>
  <li>Daily and weekly prompts and prompt reviews</li>
  <li>Links to resources</li>
  <li>Image prompting specific to mid-journey for you to play with</li>
  </ul>
  </div>
  <p>FOR NOW JUST BUTTON MASH and...</p>
  <h2>Have Fun!!!!! YEHHAAA</h2>
  </div>
  );
  }
  
  export default About;