import React from 'react';
import '../css/styles.css';


const ResourceCard = ({ images, category, title,onToggle }) => {
  return (
    <button className="prompt-card" onClick={onToggle}>
    <h3 className="prompt-card-title">{title}</h3>
    
      <img src={images} alt="" className="prompt-card-image" />
      <span className="prompt-card-text"><br/> {category}</span> 
    </button>
  );
};

export default ResourceCard;
