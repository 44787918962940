import React, { useState, useEffect } from 'react';
import data from '../data/resource_card_data.json';
import "../css/styles.css";
import ResourceCard from './ResourceCard';
import ResourceModal from './ResourceModal';
import ReactMarkdown from 'react-markdown';

const Exploration = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadMarkdown = async () => {
      if (selectedCard && selectedCard.text) {
        const response = await fetch(selectedCard.text);
        const text = await response.text();
        setMarkdownContent(text);
      }
    };

    loadMarkdown();
  }, [selectedCard]);

  const sortedData = [...data].reverse();

  const handleCardToggle = (index) => {
    setSelectedCard(sortedData[index]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <>
    <div className='intro-resources'>
      <h1>Resources</h1>
    <ReactMarkdown>
    {`### Chatbots for Content Creation:
- [**Google Bard AI**](https://bard.google.com/chat): Uses web-based information to generate text content and can be utilized for various tasks including idea generation and answering questions.
- [**Character AI**](https://beta.character.ai/): Focuses on character-based interactions, suitable for immersive chat experiences and content creation.
- [**Claude AI**](https://www.anthropic.com/): Created by Anthropic, it's designed for generating text and assisting with a range of tasks by understanding and responding to user input.
- [**ChatGPT-4**](https://openai.com/chatgpt): Developed by OpenAI, it excels at creating text content for a multitude of purposes, from writing to information queries, and can access the internet to pull in current information.
- [**DALL-E 3**](https://openai.com/dall-e-3/): While primarily for image creation, DALL-E 3's capabilities are accessed via ChatGPT Plus membership and can also be utilized for generating creative text content in conjunction with images.
- [**Bing AI**](https://www.bing.com/): Incorporates a sophisticated AI chat mode for queries and content suggestions, using the web to enhance user interaction and information delivery.

### AI for Image Creation:
- [**MidJourney AI**](https://www.midjourney.com/home/): An AI dedicated to converting textual descriptions into images, serving the needs of creative fields.
- [**Ideogram AI**](https://www.ideogram.ai/): A specialized platform for text-to-image generation, focusing on the integration of text within images.
- [**Runway AI**](https://runwayml.com/): Provides tools for image and video generation, editing, and AI-powered creative processes.
- [**DALL-E 3**](https://openai.com/dall-e-3/): OpenAI's image generation model which creates original images and art from textual descriptions provided by users.

### AI with Internet Browsing Capability:
- [**ChatGPT-4**](https://openai.com/chatgpt): Has browsing functionality, enabling it to search the internet for information and incorporate it into its responses.
- [**Google Bard AI**](https://bard.google.com/chat): Harnesses information from the web to enrich its responses and assist users with updated information.
- [**Bing AI**](https://www.bing.com/): Features enhanced AI-driven search experiences with expanded knowledge and capabilities to interpret and generate web content.
`}
  </ReactMarkdown>

    </div>
    <div className="content-area">
      {sortedData.map((item) => (
        <ResourceCard
          key={item.id}
          category={item.category.charAt(0).toUpperCase() + item.category.slice(1)}
          images={item.images}
          title={item.title}
          onToggle={() => handleCardToggle(sortedData.indexOf(item))}
        />
      ))}
      {isModalOpen && (
        <ResourceModal
          category={selectedCard.category.charAt(0).toUpperCase() + selectedCard.category.slice(1)}
          images={selectedCard.images}
          title={selectedCard.title}
          text={markdownContent}
          onClose={closeModal}
          link={selectedCard.link}
        />
      )}
    </div>
    </>
  );
};

export default Exploration;

