import '../css/about_style.css'

function Home() {
  // Add your component logic here
  return (
  <div className="welcome-text-area">
    <div className='boxed-list'>
  <h1>Welcome to the MidJourney Prompter Tut</h1>
  </div>
  <p>Congratulations on being one of the first to try out the Prompter!</p>
  <p>Let's teach you how to use this tool and its concepts in the super quick version.</p>
  <p>Conceptually, a prompt is divided into four parts:</p>
  <div className='boxed-list'>
  <ul>
  <li><strong>'Subject'</strong>: What or who is your focus. Think of it as your main character. If you want a landscape, you can totally forget this or maybe type something about a rock in a field.</li>
  <li><strong>'Setting'</strong>: What is the enviroment, atmosphere and lighting</li>
  <li><strong>'Style'</strong>: How we want our image to interpret the character in this setting.</li>
  <li><strong>'Render'</strong>: How we are processing this image and what physics are we using to create the image.</li>
  </ul>
  </div>
  <p>Understand that we are just filling in four input fields and assembling them into one.</p>
  <p>The fields can be reset one by one with <b>Reset Category</b> or all at once with <b>Reset All</b>.</p>
  <p>This alone is a strong tool for reusability. The input fields are designed for you to type and edit. You can do many different versions and rework the whole piece many times over.</p>
  <p>Especially since the save and load prompt has been created. We will be moving to a database soon.</p>
  
  <h2>Time for Button Mashing</h2>
  <p>Like any exploration, button mashing is essential. This tool is designed not to substitute your creativity but let you explore it.</p>
  <div className='boxed-list'>
  <ol>
  <li><strong>Prompter</strong>: First step is to be on the prompter which is in the menu.</li>
  <li><strong>Pick a Category</strong>: Hit one of the five categories on top. That will initialize the Category input field and the Subcategories.</li>
  <li><strong>Pick a Subcategory</strong>: Hitting the Subcategory button will initiate all the topics to populate below.</li>
  <li><strong>Pick a Topic</strong>: The topic buttons have a star on them to show you enjoy that one. Also note that there are pages of topics, and they are alphabetical as well.</li>
  <li><strong>Pick a Prompt</strong>: Right now, there are four. First, there is the name. Second is a description. The last buttons are randomly generated and need some love later on to get up to par.</li>
  <li><strong>Repeat</strong>: Go and do the same for the other categories, and feel free to type in the input. That's why it's there.</li>
  <li><strong>Copy</strong>: Hit the <b>Copy Prompt</b>button to copy the prompt for you to enter into mid-journey.</li>
  <li><strong>Don't Forget</strong>: You can clear a single category to rework it or change it entirely.</li>
  <li><strong>Reset All</strong>: Start over again with the "Reset All" button, or save, or load an old prompt into the fields</li>
</ol>
</div>
<h2>Disclaimers</h2>
<p>If you are here, you are super early, and I'm just playing with a lot of unprocessed data. If any of it offends you, I'm sorry.</p>
<p>Yes, there are double buttons and all sorts of weird things, but it is stable and fun, and super useful as well.</p>
<p>Most of all, enjoy yourself. Go have some fun, give some feedback. My Discord is in the About section.</p>
<p>If you feel like supporting the project, please let me know. There is a lot of fun work and joy to be had.</p>
<h3>Stop Reading!!!! ,GO GET SOME AI EYE CANDY!!! <br/>Save the pics and send them to me or reference FervorLife#6418 for now</h3>
</div>
);
}

export default Home;