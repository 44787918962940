import React from "react";
import ArticleCard from "./ArticleCard";

import data from '../data/article_card_data.json';

function Articles() {
  return (
    <div className='article-contentArea'>
      {data.map((article, index) => (
        <ArticleCard key={index} article={article} />
      ))}
    </div>
  );
}

export default Articles;

  