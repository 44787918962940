import NavMenu from "./NavMenu";
import header_logo from "../images/logo_promptology_white-01.png";



function Header () {
    // Add your component logic here
    return (
        <div className="header-container">
        <div className="title-area">Promptology.dev</div>
        <div className="menu-area">
        <NavMenu/>
        </div>
        <div className="logo-area">
          <img className="logo-image" src={header_logo} alt="promptology logo" />
        </div>
      </div>
    );
  }
  export default Header;
  