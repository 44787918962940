import React from "react";

function InputField({
  value,
  onChange,
  placeholder,
  resetAllCategories,
  resetActiveCategory,
  activeCategory,
  color,
  handleCopyText
}) {
  return (
    <div className={`input-field ${color}`}>
     
      <input
        className={`category-input-field ${color}`}
        value={value.toString()}
        onChange={onChange}
        placeholder={placeholder}
      />
    <div className="input-button-area">
      <button className="input-button" onClick={resetActiveCategory}>
        Reset {activeCategory} 
      </button>
      <button className="input-button" onClick={resetAllCategories}>
        Reset All
      </button>
      <button className="input-button" onClick={handleCopyText}>
        Copy Prompt
      </button>
    </div>
    </div>
  );
}

export default InputField;
