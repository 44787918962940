// ArticleModal.js
import React from "react";
import ReactMarkdown from "react-markdown";


function ArticleModal({ summaryData,title,image, closeModal, onClose }) {
  return (
    <div className='article-modal'onClick={closeModal}>


    <div className='article-modal-content'>
      <div className='article-modal-image-area'>
      <img className='article-modal-image' src={image} alt={title} />
      </div>
      <button onClick={onClose} className="modal-close">&times;</button>
      <div className='article-modal-title'>{title}</div>
      <div className='article-modal-text'>
      
        <ReactMarkdown>{summaryData}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default ArticleModal;

