import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/Home";
import About from "./components/About";
import Promptivate from "./components/Promptivate";
import Header from "./components/Header"
import './css/styles.css';
import Exploration from './components/Exploration';
import Articles from './components/Articles';
import Resources from './components/Resources';

function App() {
  return (

    <div className="container">
     
      <Router>
      
        <div>
        <Header/>
          <Routes>
            <Route path="/" element={<Promptivate />} />
            <Route path="/exploration" element={<Exploration />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/about" element={<About />} />
            <Route path="/home" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;

