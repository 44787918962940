import React from "react";

function CategoryButton({ category, onClick, selected }) {
  return (
    <button className={`category-button ${selected ? 'selected' : ''}`} onClick={onClick}>
    {category.name}
  </button>
  );
}

export default CategoryButton;
