// ArticleCard.js
import React, { useState, useEffect } from "react";
import ArticleModal from "./ArticleModal";


function ArticleCard({ article }) {
  const [showModal, setShowModal] = useState(false);
  const [summaryData, setSummaryData] = useState("");

  const handleSummaryClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    async function fetchMarkdown() {
      const response = await fetch(article.summary);
      const text = await response.text();
      console.log("Fetched summary data:", text);
      setSummaryData(text);
    }

    fetchMarkdown();
  },);

  return (
    <div className='article-card'>
      <img className='article-card-image' src={article.image} alt={article.name} />
      <div className='article-card-info'>
        {article.name}
        <button className='article-summary-btn' onClick={handleSummaryClick}>
          {article.read}
        </button>
        {showModal && (
          <ArticleModal 
          summaryData={summaryData} 
          title={article.name}
          image={article.image}
          closeModal={closeModal} />
        )}
      </div>
    </div>
  );
}

export default ArticleCard;

