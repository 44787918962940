import React, { useState, useEffect } from "react";
import CategoryButton from "./CategoryButton";
import InputField from "./InputField";
import OutputArea from "./OutputArea";
import TopicArea from "./TopicArea";
import PopUp from "./PopUp";
import SavedPromptPopup from "./SavedPromptPopup";
import SubCategoryButton from "./SubCategoryButton";
import page_forward_arrow from "../images/page_arrow_forward.png";
import page_back_arrow from "../images/page_arrow_back.png";
import RandomBackgroundImage from "../components/RandomBackgroundImage";
import subCategory from "../data/subCategory_buttons.json";

function Promptivate() {
  //retrieving favorites//
  const [favorites, setFavorites] = useState(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    console.log("Retrieved favorites from local storage:", storedFavorites);
    return storedFavorites;
  });
  // Initialize state for categories array and active category index
  const [categories, setCategories] = useState([
    { name: "Subject", prefix: " ", value: "", className: "subject-color" },
    { name: "Setting", prefix: " Setting is ", value: "", className: "setting-color" },
    { name: "Style", prefix: " Style of ", value: "", className: "style-color" },
    { name: "Render", prefix: " ", value: "", className: "render-color" },
  ]);


  const [activeIndex, setActiveIndex] = useState(null);
  const [output, setOutput] = useState([]);
  const [currentSubcategories, setCurrentSubcategories] = useState([]);
  const [topicData, setTopicData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [numPageButtons, setNumPageButtons] = useState(10);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeSubcategoryIndex, setActiveSubcategoryIndex] = useState(null);
  const [selectedTopicId, setSelectedTopicId] = useState(null);

  const [randomButtonValues, setRandomButtonValues] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const activeCategory = categories[activeIndex];

  ////saved Prompts loading
  const [showSavedPromptsPopup, setShowSavedPromptsPopup] = useState(false);
const closeSavedPromptsPopup = () => setShowSavedPromptsPopup(false);
  const [savedPrompts, setSavedPrompts] = useState(() => {
    const storedPrompts = JSON.parse(localStorage.getItem("savedPrompts")) || [];
    return storedPrompts;
  });
  const onSaveCurrentPrompt = () => {
    setShowSavedPromptsPopup(false);
    setSavedPrompts([...savedPrompts, categories]);
  };
  const loadSavedPrompt = (index) => {
    setShowSavedPromptsPopup(false);
    setCategories(savedPrompts[index]);
  };
  useEffect(() => {
    localStorage.setItem("savedPrompts", JSON.stringify(savedPrompts));
}, [savedPrompts]);

  const handleClearPrompt = (index) => {
    const newSavedPrompts = [...savedPrompts];
    newSavedPrompts.splice(index, 1);
    setSavedPrompts(newSavedPrompts);
};


  ///responsive jsx
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 760) {
        setNumPageButtons(6);
        setItemsPerPage(4);
      } else {
        setNumPageButtons(10);
        setItemsPerPage(7);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }); // Empty array ensures that effect is only run on mount and unmount

  const handleCategoryClick = (index) => {
    setActiveIndex(index);
    setCurrentSubcategories(subCategory[index].subtopics);
    setTopicData([]);
    setActiveSubcategoryIndex(null);
    setSelectedItem({ name: "Choose a subcategory" });
    setShowPopup(false);
  };

  // Function to handle changes in the input field for the active category
  const handleInputChange = (index, event) => {
    const newCategories = [...categories];
    newCategories[index].value = event.target.value;
    setCategories(newCategories);
  };

  //OUTPUT AREA//
  // CREATING THE OUTPUT FIELD
  useEffect(() => {
    const newOutput = categories
      .filter(category => category.value)
      .map(category => ({
        text: category.prefix + category.value,
        className: category.className
      }));
    setOutput(newOutput);
  }, [categories]);
  

  const resetActiveCategory = () => {
    const newCategories = [...categories];
    newCategories[activeIndex].value = "";
    setCategories(newCategories);
  };
  const resetAllCategories = () => {
    const newCategories = categories.map((category) => ({
      ...category,
      value: "",
    }));
    setCategories(newCategories);
    setOutput([]);
    setActiveIndex(null);
    setCurrentSubcategories([]);
    setTopicData([]);
    setActiveSubcategoryIndex(null);
    setSelectedItem(null);
  };

  const handleCopyText = () => {
    const textArea = document.createElement("textarea");
    textArea.value = output.map(item => item.text).join(" ");
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const handleSubcategoryClick = (index) => {
    const url = currentSubcategories[index].dataUrl;
    console.log(url);
    fetchTopicData(url);
    setActiveSubcategoryIndex(index);
    setCurrentPage(1);
    setShowPopup(false);
  };

  const fetchTopicData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      data.sort((a, b) => a.name.localeCompare(b.name));
      setTopicData(data);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTopicButtonClick = (id) => {
    const selectedItem = topicData.find((item) => item.id === id);

    // Set the selected item
    setSelectedItem(selectedItem);
    setShowPopup(true);
    setSelectedTopicId(id);

    // Generate random button values and update the state
    const composedButtonNames = createComposedButtonNames(selectedItem, 2);
    setRandomButtonValues(composedButtonNames);
  };

  const handlePageButtonClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevButtonClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextButtonClick = () => {
    const totalPages = Math.ceil(topicData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  const handlePopupButtonClick = (buttonName) => {
    const newCategories = [...categories];
    const currentValue = newCategories[activeIndex].value;
    newCategories[activeIndex].value = currentValue
      ? `${currentValue} ${buttonName}`
      : buttonName;
    setCategories(newCategories);
    setShowPopup(false);
  };
  const createComposedButtonNames = (item, numButtons) => {
    const composedButtonNames = [];

    for (let i = 0; i < numButtons; i++) {
      const randomType =
        item.type && item.type[Math.floor(Math.random() * item.type.length)];
      const randomPhysicality =
        item.physicality &&
        item.physicality[Math.floor(Math.random() * item.physicality.length)];
      const randomTrait =
        item.trait && item.trait[Math.floor(Math.random() * item.trait.length)];

      const prefix = item.prefix || [" ", " ", " ", ". ", "."];
      const composedButtonName = `${prefix[0]}${" "}${randomType} ${prefix[1]} ${randomPhysicality} ${prefix[2]} ${randomTrait}${prefix[3]}`;

      composedButtonNames.push(composedButtonName);
    }

    return composedButtonNames;
  };
  //favorite button//
  const toggleFavorite = (itemId) => {
    const index = favorites.indexOf(itemId);
    if (index === -1) {
      setFavorites([...favorites, itemId]);
    } else {
      setFavorites(favorites.filter((id) => id !== itemId));
    }
  };
  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);
  useEffect(() => {
    console.log("Saving favorites to local storage:", favorites);
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  return (
    <div className="prompter-container">
      <RandomBackgroundImage />
      <div className="category-area">
        {categories.map((category, index) => (
          <CategoryButton
            key={index}
            category={category}
            onClick={() => handleCategoryClick(index)}
            selected={index === activeIndex}
          />
        ))}
      </div>
      <div className="subcategory-area">
        {currentSubcategories.length === 0 ? (
          <div className="initial-subcategory-text">
            <span className="instruct-text">Choose a category above.</span>
          </div>
        ) : (
          currentSubcategories.map((subcategory, index) => (
            <SubCategoryButton
              key={index}
              subcategory={subcategory}
              isActive={activeSubcategoryIndex === index}
              onClick={() => handleSubcategoryClick(index)}
            />
          ))
        )}
      </div>

      <TopicArea
        topicData={topicData}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        handlePrevButtonClick={handlePrevButtonClick}
        handleNextButtonClick={handleNextButtonClick}
        handlePageButtonClick={handlePageButtonClick}
        handleTopicButtonClick={handleTopicButtonClick}
        toggleFavorite={toggleFavorite}
        favorites={favorites}
        selectedTopicId={selectedTopicId}
        selectedItem={selectedItem}
        numPageButtons={numPageButtons}
        showSavedPopup= {setShowSavedPromptsPopup}
        page_back_arrow={page_back_arrow}
        page_forward_arrow={page_forward_arrow}
      />

      {showPopup && (
        <PopUp
          item={selectedItem}
          onButtonClick={handlePopupButtonClick}
          onClose={closePopup}
          randomButtonValues={randomButtonValues}
        />
      )}
      {showSavedPromptsPopup && (
  <SavedPromptPopup
  savedPrompts={savedPrompts}
  onClose={closeSavedPromptsPopup}
  onLoad={loadSavedPrompt}
  onClear={handleClearPrompt}
  currentPrompt={categories}
  onSaveCurrentPrompt={onSaveCurrentPrompt}
/>
)}

      <div className="input-area">
        {activeCategory && (
          <InputField
            activeCategory={activeCategory.name}
            value={activeCategory.value}
            onChange={(event) => handleInputChange(activeIndex, event)}
            placeholder={`Enter ${activeCategory.name}`}
            resetActiveCategory={resetActiveCategory}
            resetAllCategories={resetAllCategories}
            color={activeCategory.className}
            handleCopyText={handleCopyText}
          />
        )}
      </div>
      

      <OutputArea
        disabled={activeCategory?.value === ""}
        output={output}
      />
    </div>
  );
}
export default Promptivate;
