import React from "react";

function SubCategoryButton(props) {
  const { subcategory, isActive, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`sub-category-button ${isActive ? 'selected' : ''}`}
    >
      {subcategory.label}
    </button>
  );
}

export default SubCategoryButton;