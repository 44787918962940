function SavedPromptPopup({ savedPrompts, onClose, onLoad, onClear, currentPrompt,onSaveCurrentPrompt }) {
    const currentPromptText = currentPrompt.map((category) => category.value).join(" ");
  
    return (
      <div className="saved-prompt-popup">
       
        <h2>Current Prompt</h2>
        <button className="close-button" onClick={onClose}>X</button>
                <p>{currentPromptText}
          <button onClick={onSaveCurrentPrompt}>Save</button>
          </p>
        <div className="saved-prompt-container">
        <h3>Saved prompts</h3>
        <hr></hr>
        {[...savedPrompts].reverse().map((prompt, index) => (
          <div className="saved-prompt-card" key={index}>
            {prompt.map((category) => category.value).join(" ")}
            <br/>
            <button onClick={() => onLoad(savedPrompts.length - 1 - index)}>Load</button>
            <button onClick={() => onClear(savedPrompts.length - 1 - index)}>Clear</button>
         </div>
        ))}
      </div>
      </div>
    );
  }
  

export default SavedPromptPopup;
