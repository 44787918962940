import React from 'react';
import ReactMarkdown from 'react-markdown';

const ResourceModal = ({ images, category, title, text,link, onClose }) => {
  return (
    <div className="prompt-modal">
      <div className="prompt-modal-content">
        <div className="prompt-modal-main-image">
          <img key={title} src={images} alt="" className="prompt-card-image" />
        </div>
        <div className='prompt-modal-label'>
        <span className='prompt-modal-title'>{title} </span>
        <span className='prompt-modal-subtitle'>{category}</span>
        
        </div>
        <button onClick={onClose} className="modal-close">&times;</button>
        <div className="prompt-modal-text">
          <ReactMarkdown>{text}</ReactMarkdown>
        <a href={link}>{title}</a>
        </div>
      </div>
    </div>
  );
};

export default ResourceModal;