import React from "react";

function OutputArea({ output }) {
  return (
    <div className="output-area">
      {!output || output.length === 0 ? (
        <p className="output-pretext">Each category has its own color</p>
      ) : (
        output.map((item, index) => (
          <span key={index} className={item.className}>
            {item.text}
          </span>
        ))
      )}
    </div>
  );
}

export default OutputArea;