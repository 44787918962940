import{ useState, useEffect } from 'react';

const RandomBackgroundImage = () => {
  const images = [
    './images/bluesky_bkg.png',
    './images/bluesky_bkg.png',
    './images/bluesky_bkg.png',
    // Add more image URLs here
  ];

  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

  const chooseRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setBackgroundImageUrl(images[randomIndex]);
  };

  useEffect(() => {
    chooseRandomImage(); // Set initial background image

    const intervalId = setInterval(() => {
      chooseRandomImage();
    }, 60 * 1000); // Change the background image every minute

    return () => {
      clearInterval(intervalId); // Clean up the timer when the component is unmounted
    };
  },);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImageUrl})`;
  }, [backgroundImageUrl]);

  return null; // This component doesn't render any visible elements
};

export default RandomBackgroundImage;
