import React from 'react';
import page_forward_arrow from "../images/page_arrow_forward.png";
import page_back_arrow from "../images/page_arrow_back.png";
import load_icon from "../images/load_icon-01.png";

const Pagination = ({ topicData, itemsPerPage, currentPage, handlePrevButtonClick, handleNextButtonClick, handlePageButtonClick, handleTopicButtonClick, toggleFavorite, favorites, selectedTopicId, selectedItem, numPageButtons,showSavedPopup}) => {
  const totalPages = Math.ceil(topicData.length / itemsPerPage);

  const getPageNumbers = () => {
    if (totalPages <= numPageButtons) {
      return Array.from(
        { length: totalPages },
        (_, index) => index + 1
      );
    } else {
      const startPage = Math.max(1, currentPage - numPageButtons / 2);
      const endPage = Math.min(
        totalPages,
        startPage + numPageButtons - 1
      );
      if (endPage - startPage < numPageButtons - 1) {
        return Array.from(
          { length: numPageButtons },
          (_, index) => endPage - (numPageButtons - 1) + index
        );
      } else {
        return Array.from(
          { length: numPageButtons },
          (_, index) => startPage + index
        );
      }
    }
  };

  return (
    <div className="topic-area">
      <div className="prev-button-area">
        <button
          className="page-button"
          onClick={handlePrevButtonClick}
          disabled={currentPage === 1}
        >
          <img
            className="page-button-icon"
            src={page_back_arrow}
            alt="prev page arrow"
          />
        </button>
        <br/><br/>
        <button
          className="page-button"
          onClick={showSavedPopup}
        >
          <img
            className="page-button-icon"
            src={load_icon}
            alt="load icon"
          />
          load/save
        </button>
      </div>
      <div className="topic-info-area">
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageButtonClick(pageNumber)}
            className={`page-button ${
              pageNumber === currentPage ? "selected" : ""
            }`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <div className="next-button-area">
        <button
          className="page-button"
          onClick={handleNextButtonClick}
          disabled={currentPage === totalPages}
        >
          <img
            className="page-button-icon"
            src={page_forward_arrow}
            alt="forward page arrow"
          />
        </button>
      </div>
      <div className="topic-button-area">
        {topicData.length === 0 ? (
          selectedItem ? (
            <div className="instruct-text">
              <h3>{selectedItem.name}</h3>
            </div>
          ) : (
            <div className="instruct-text">
              <h3>MidJourney Prompter</h3>
              <ul>
                <li>Every category has a text field</li>
                <li>Fill the fields by typing or clicking buttons</li>
                <li>Experiment and have fun!</li>
              </ul>
            </div>
          )
          ) : (
            [...topicData] // create a copy of topicData to avoid mutating the original array
              .sort((a, b) => {
                // If a is favorited and b is not, a should come first
                if (favorites.includes(a.id) && !favorites.includes(b.id)) {
                  return -1;
                }
                // If b is favorited and a is not, b should come first
                if (!favorites.includes(a.id) && favorites.includes(b.id)) {
                  return 1;
                }
                // If both are favorited or both are not, sort alphabetically
                return a.name.localeCompare(b.name);
              })
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((item) => {
              const buttonKey = item.id;
              const label =
                item.name.length > 15
                  ? `${item.name.slice(0, 22)}...`
                  : item.name;
              return (
                <button
                key={buttonKey}
                onClick={() => handleTopicButtonClick(item.id)}
                className={`topic-button ${
                  favorites.includes(item.id) ? "favorite" : ""
                }${item.id === selectedTopicId ? "selected" : ""}`}
              >
                {label}
                <span
                  className="favorite-icon"
                  onClick={(event) => {
                    event.stopPropagation(); // prevent handleTopicButtonClick from being called
                    toggleFavorite(item.id);
                  }}
                >
                  {favorites.includes(item.id) ? "★" : "☆"}
                </span>
              </button>
            );
          })
      )}
    </div>
  </div>
);
};

export default Pagination;
