import React from "react";

const Popup = ({ item, onButtonClick, onClose, randomButtonValues }) => {
  if (!item) return null;
  const firstPrefix = item.prefix && item.prefix[0] ? item.prefix[0] : " ";
  const lastPrefix = item.prefix && item.prefix[3] ? item.prefix[3] : " ";

  return (
    <div className="popup-container">
      <div className="popup-header">
        <div className="topic">{item.name}</div>
        <button className="close-button" onClick={onClose}>
          X
        </button>
      </div>
      <button className="pop-button" onClick={() => onButtonClick(`${firstPrefix}${" "}${item.name}${lastPrefix}`)}>
        {firstPrefix} {item.name}
      </button>
      <button className="pop-button" onClick={() => onButtonClick(`${firstPrefix}${" "}${item.description}`)}>
        {firstPrefix} {item.description}
      </button>
      {randomButtonValues.map((randomButtonName, index) => (
        <button
          className="pop-button"
          key={index}
          onClick={() => onButtonClick(randomButtonName)}
        >
          {randomButtonName}
        </button>
      ))}
    </div>
  );
};

export default Popup;
