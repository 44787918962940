import React from 'react';
import '../css/styles.css';
import ReactMarkdown from 'react-markdown';

const PromptCardModal = ({ images, category, title, text, onClose }) => {
  return (
    <div className="prompt-modal">
      <div className="prompt-modal-content">
        <div className="prompt-modal-main-image">
          <img src={images[0]} alt="" className="prompt-card-image" />
        </div>
        <div className='prompt-modal-label'>
        <span className='prompt-modal-title'>{title} </span>
        <span className='prompt-modal-subtitle'>Prompt Catagory: {category}</span>
        
        </div>
        <button onClick={onClose} className="modal-close">&times;</button>
        
        
        <div className="prompt-modal-images">
          {images.slice(1).map((image, index) => (
            <img key={index} src={image} alt="" className="prompt-card-image" />
          ))}
        </div>
        <div className="prompt-modal-text">
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default PromptCardModal;


