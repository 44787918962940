import React from 'react';
import '../css/styles.css';


const PromptCard = ({ images, category, title, onToggle }) => {
  return (
    <button className="prompt-card" onClick={onToggle}>
    <h3 className="prompt-card-title">{title}</h3>
    
      <img src={images[0]} alt="" className="prompt-card-image" />
      <span className="prompt-card-text">Prompt Category <br/> {category}</span> 
    </button>
  );
};

export default PromptCard;
