import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/NavMenu.module.css';
import menuIcon from "../images/prompt_hamburger-01.png";

const NavMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav>
      <div className={styles.menuButton} onClick={handleToggleMenu}>
        <span><img className='menu-icon' src={menuIcon} alt="menu" ></img></span>
      </div>
      <ul className={`${styles.menu} ${showMenu ? styles.show : ''}`}>
        <li>
          <Link to="/" className={styles.link} onClick={handleToggleMenu}>Prompter</Link>
        </li>
        <li>
          <Link to="/exploration" className={styles.link}  onClick={handleToggleMenu}>Exploration</Link>
        </li>
        <li>
          <Link to="/articles" className={styles.link}  onClick={handleToggleMenu}>Articles</Link>
        </li>
        <li>
          <Link to="/resources" className={styles.link}  onClick={handleToggleMenu}>Resources</Link>
        </li>
        <li>
          <Link to="/about" className={styles.link}  onClick={handleToggleMenu}>About</Link>
        </li>
        <li>
          <Link to="/home" className={styles.link}  onClick={handleToggleMenu}>Tutorial</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
