import React, { useState, useEffect } from 'react';
import data from '../data/prompt_card_data.json';
import "../css/styles.css";
import PromptCard from './PromptCard';
import PromptCardModal from './PromptCardModal';

const Exploration = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadMarkdown = async () => {
      if (selectedCard && selectedCard.text) {
        const response = await fetch(selectedCard.text);
        const text = await response.text();
        setMarkdownContent(text);
      }
    };

    loadMarkdown();
  }, [selectedCard]);

  const sortedData = [...data].reverse();

  const handleCardToggle = (index) => {
    setSelectedCard(sortedData[index]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div className="content-area">
      {sortedData.map((item) => (
        <PromptCard
          key={item.id}
          category={item.category.charAt(0).toUpperCase() + item.category.slice(1)}
          images={item.images}
          title={item.title}
          onToggle={() => handleCardToggle(sortedData.indexOf(item))}
        />
      ))}
      {isModalOpen && (
        <PromptCardModal
          category={selectedCard.category.charAt(0).toUpperCase() + selectedCard.category.slice(1)}
          images={selectedCard.images}
          title={selectedCard.title}
          text={markdownContent}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default Exploration;

